import Sidebar from '../../components/Sidebar'
import withFavorite from '../../hoc/favorite'
import { useState, useMemo, useEffect } from 'react'
import {getCountries} from '../../api/competitionsApi'
import dynamic from 'next/dynamic'
import Head from 'next/head'
import {MatchDetailBlocks} from '../../components/match-details';
import {
    getMatch,
    getMatchDetails,
    getMatchEvents,
    getMatchForecasts,
    getMatchLineups, getMatchStandings,
    getOdds,
    getSeries,
    getSurebets, getTopCompetitions, isRequestFromBot, matchApi,
} from '../../api';
import { setMatchCloseFallback, setMatchId } from '../../features/match'

const CountriesBlock = dynamic(
    () => import('../../components/CountriesBlock'),
    {
        ssr: true,
        loading: () => <div className="preloader"></div>,
    }
)

const MatchPage = (_props: any) => {
    const [widthWindow, setWidthWindow] = useState(0)

    // resize
    useEffect(() => {
        const resizeListener = () => {
            setWidthWindow(window.innerWidth)
        }
        setWidthWindow(window.innerWidth)
        window.addEventListener('resize', resizeListener)
    }, [])

    const showSideBar = useMemo(() => {
        return widthWindow >= 860
    }, [widthWindow])

    return (
        <>
            {_props.meta && (
                <Head>
                    <title>{_props.meta.title}</title>
                    <meta name='description' content={_props.meta.description} />
                    {_props.hreflang && Object.keys(_props.hreflang).map((langKey) => (
                        <link
                            key={langKey}
                            rel='alternate'
                            hrefLang={langKey}
                            href={_props.baseUrl + _props.hreflang[langKey]}
                        />
                    ))}
                </Head>
            )}
            <div className="container container--mobile">
                <div className="flex grid grid--12">
                    {showSideBar && (
                        <Sidebar side="left">
                            <CountriesBlock />
                        </Sidebar>
                    )}
                    {/*Необходимо жестко зафиксировать ширину контента, для корректного расчета ширины
                    при использовании хука useContainerWidth*/}
                    <div className="content flex"
                        style={{
                            display: 'grid',
                            gridTemplateColumns: '1fr',
                        }}
                    >
                        <MatchDetailBlocks
                            matchId={_props.id}
                            isBlockFullSize
                            isMatchPage
                        />
                    </div>
                </div>
            </div>
        </>

    )
}

export const MatchPageWithFavourite = withFavorite<any>(MatchPage) as any

MatchPageWithFavourite.getSSRProps = (async (store: any, context: any) => {
    const id = context.query.id[0];

    store.dispatch(setMatchId(Number(id)))
    store.dispatch(setMatchCloseFallback('match'))

    const { data } = await store.dispatch(
        getMatch.initiate({
            id: id,
            locale: context.locale,
        })
    )
    if (isRequestFromBot(context)) {
        const requests = [
            store.dispatch(
                getOdds.initiate({
                    id: id,
                    type: data.data.live ? 'live' : 'regular',
                    locale: context.locale,
                })
            ),
            store.dispatch(
                getSurebets.initiate({
                    id: id,
                    locale: context.locale,
                })
            ),
            store.dispatch(
                getMatchEvents.initiate({
                    id: id,
                    locale: context.locale,
                })
            ),
            store.dispatch(
                getMatchDetails.initiate({
                    id: id,
                    locale: context.locale,
                })
            ),
            store.dispatch(
                getMatchLineups.initiate({
                    id: id,
                    locale: context.locale,
                })
            ),
            store.dispatch(
                getSeries.initiate({
                    id: id,
                    locale: context.locale,
                })
            ),
            store.dispatch(
                getMatchForecasts.initiate({
                    id: id,
                    locale: context.locale,
                })
            ),
            store.dispatch(
                getTopCompetitions.initiate({ locale: context.locale })
            ),
            store.dispatch(
                getMatchStandings.initiate({
                    id: id,
                    locale: context.locale,
                })
            ),
            store.dispatch(
                getCountries.initiate({ locale: context.locale })
            ),
        ]
        const requestPromses = Promise.all(requests)
        const storePromises = Promise.all(
            store.dispatch(matchApi.util.getRunningQueriesThunk())
        )
        await Promise.all([requestPromses, storePromises])
    }

    return {
        id: id,
    }
});
